import React, {useState, useEffect} from 'react';
import { useLocation } from 'react-router-dom';
import './SubCategory.component.scss';
import { useNavigate } from 'react-router-dom';
import Footer from '../Footer/Footer.component.tsx';

const SubCategory = () => {
    const [subCategoryData, setSubCategoryData] = useState([])
    const { state } = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        console.log(state, 'state')
        setSubCategoryData(state.subCategoriesFound)
    }, [])

    const handleSubCategorySelection = (value) => {
        console.log(value, 'value')
        navigate('/listing', {
            state: {
                category: state.category,
                subcategory: value
            }
        })
    }

    return (
        <>
        <div className='subcategories-main-container'>
            <div className='subcategories-back-container'>
                <div className='subcategories-back-button' onClick={() => navigate('/')}>
                    <img src={`https://nearvisit-website.s3.ap-south-1.amazonaws.com/common/back-arrow.png`} />
                    <p>back</p>
                </div>
            </div>
            <div className='subcategories-inner-container'>
                {subCategoryData.map((v, i) => {
                    return <div className='subcategories-content' key={i} onClick={() => handleSubCategorySelection(v.name, v.imageName)}>
                        <div className='subcategories-content-icon'>
                            {/* <img src={bundBed} /> */}
                        <img src={`https://nearvisit-website.s3.ap-south-1.amazonaws.com/common/${v.imageName}.png`} />
                        </div>
                        <div className='subcategories-content-icon-desc'>
                            {v.name}
                        </div>
                    </div>
                })}
            </div>
        </div>
        <Footer />
        </>
    )
}

export default SubCategory;