import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./Pages/Home/Home.component.tsx";
import Login from "./Components/Login/Login.component.tsx";
import Signup from "./Components/Signup/Signup.component.tsx";
import SubCategory from "./Components/SubCategory/SubCategory.component.tsx";
import IndexBusinessListing from "./Components/BusinessListing/IndexBusinessListing/IndexBusinessListing.component.tsx";
import BusinessListingDetail from "./Components/BusinessListing/BusinessListingDetail/BusinnessListingDetail.component.tsx";
import ShowAllCategories from "./Components/ShowAllCategories/ShowAllCategories.component.tsx";
import ViewBusiness from "./Pages/ViewBusiness/ViewBusiness.component.tsx";
import PaymentSuccessPage from "./Components/PaymentSuccessPage/PaymentSuccessPage.component.tsx";
import { ShowAllBusinesses } from "./Components/ShowAllBusinesses/ShowAllBusinesses.component.tsx";
import CreateBusiness from "./Components/BusinessListing/CreateBusiness/CreateBusiness.component.tsx";

export default function App() {
  return (
    <BrowserRouter>
      <Routes>
        {/* <Route path="/" element={<Layout />}> */}
        {/* <Route index element={<Login />} /> */}
        <Route path="business-listing" element={<IndexBusinessListing />} />
        <Route path="business-listing-create" element={<CreateBusiness />} />
        <Route
          path="business-listing-businessId"
          element={<BusinessListingDetail />}
        />
        <Route path="business/:businessId" element={<ViewBusiness />} />
        <Route path="show-all-categories" element={<ShowAllCategories />} />
        <Route path="login" element={<Login />} />
        <Route path="signup" element={<Signup />} />
        <Route path="subcategory" element={<SubCategory />} />
        <Route path="payment-success" element={<PaymentSuccessPage />} />
        <Route path="/" element={<Home />} />
        <Route path="/categories" element={<ShowAllCategories />} />
        <Route path="*" element={<ShowAllBusinesses />} />
      </Routes>
    </BrowserRouter>
  );
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<App />);
