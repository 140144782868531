import React, { useState, useEffect } from 'react';
import './Login.component.scss';
import { TextField } from '@mui/material';
import axios from "axios";
import { useNavigate } from 'react-router-dom';

const Login = () => {
    const [phone, setPhone] = useState('');
    const [password, setPassword] = useState('')
    const navigate = useNavigate();
    const [error, setError] = useState({ message: '' });
    const [isLoginValid, setIsLoginValid] = useState(false)

    const handleForm = (e) => {
        console.log(e.target.name)
        console.log(e.target.value)
        if (e.target.name == "phone") {
            if(e.target.value.length > 10) {
                console.log('more than 10')
                // setError({ message: 'Phone number cannot be more than 10 numbers'})
            } else {
                setPhone(e.target.value)
            }
        }
        if (e.target.name == "password") {
            setPassword(e.target.value)
            if (phone && password) {
                setError({ message: ''})
                setIsLoginValid(true)
            } else {
                setIsLoginValid(false)
            }
        }
    }

    const handleSubmit = (e) => {
        console.log('handleSubmit')
        if (phone === "") {
            setError({ message: 'Phone number cannot be empty' })
            setIsLoginValid(false)
        } else if (phone.length <10 ) {
            setError({ message: 'Phone number cannot be lesser than 10 numbers'})
            setIsLoginValid(false)
        } else if (password === "") {
            setError({ message: 'Password cannot be empty' })
            setIsLoginValid(false)
        } else {
            console.log('login')
            setError({ message: '' })
            setIsLoginValid(true)
            axios({

                // Endpoint to send files
                url: "https://api.nearvisit.com/user/signin",
                method: "POST",
                headers: {

                    // Add any auth token here
                    authorization: "your token comes here",
                },

                // Attaching the form data
                data: {
                    phone: phone,
                    password: password
                },
            })

                // Handle the response from backend here
                .then((res) => {
                    console.log(res)
                    localStorage.setItem('token', res.data.token)
                    navigate('/')
                    //   setRowData(res.data.category)
                })

                // Catch errors if any
                .catch((err) => { 
                    console.log(err, 'err')
                    console.log(err.response.data.message, 'err.response.data.message')
                    setError({message: err.response.data.message} )
                });
            // axios.get()
        }
    }


    const handleSignup = () => {
        navigate('/signup')
    }

    const handleBlur = () => {
        if (phone === "") {
            setError({ message: 'Phone number cannot be empty'})
            setIsLoginValid(false)
        } else if (phone.length <10 ) {
            setError({ message: 'Phone number cannot be lesser than 10 numbers'})
            setIsLoginValid(false)
        } else if (phone.length >10 ) {
            setError({ message: 'Phone number cannot be greater than 10 numbers'})
            setIsLoginValid(false)
        } else if (password === ""){
            setError({ message: 'Password cannot be empty'})
            setIsLoginValid(false)
        } else {
            console.log('all')
            setError({ message: ''})
            setIsLoginValid(true)
        }
    }

    return (
        <div>
            {isLoginValid}
            <div className='login-main-container'>
                <div className='login-inner-container'>
                    <div className='login-content'>
                        <div className='login-content-title'>
                            Login
                        </div>
                        {
                        error && error ? (
                            <div className='login-error-message'>{error.message}</div>
                        ) : ''
                    }
                        <TextField className='login-form-field' onBlur={handleBlur} id="outlined-basic" label="Phone Number" variant="outlined" name='phone' onChange={handleForm} value={phone} />
                        <TextField className='login-form-field' onBlur={handleBlur} id="outlined-basic" label="Password" variant="outlined" name="password" onChange={handleForm} value={password} type='password' />
                        <div className='login-content-action'>
                            <button className='login-content-submit' style={isLoginValid ? ({ backgroundColor: 'blue'}) : ({ backgroundColor: 'grey'})} disabled={!isLoginValid} onClick={handleSubmit}>Submit</button>
                            <button className='login-content-cancel'>Cancel</button>
                        </div>
                        <div className='login-content-signup'>
                            <div className='login-content-signup-desc'>Don't have an account? <span onClick={handleSignup} style={{ color: 'darkblue', cursor: 'pointer' }}>Signup</span></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Login;