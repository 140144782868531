import React from 'react';
import './Footer.component.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHouse, faUser, faMagnifyingGlass, faList } from '@fortawesome/free-solid-svg-icons'
import { useNavigate } from 'react-router-dom';

const Footer = () => {
    const navigate = useNavigate();

    return (
        <div className='footer-main-container'>
            <div className='footer-inner-container'>
                <div className='footer-content'>
                    <div className='footer-content-itemOne' onClick={() => navigate('/')}><div className='footer-content-itemOne-inner'><FontAwesomeIcon icon={faHouse} /><div className='footer-content-itemOne-title'>Home</div></div></div>
                    <div className='footer-content-itemTwo' onClick={() => navigate('/search')}><div className='footer-content-itemTwo-inner'><FontAwesomeIcon icon={faMagnifyingGlass} /><div className='footer-content-itemTwo-title'>Search</div></div></div>
                    <div className='footer-content-itemThree' onClick={() => navigate('/listing')}><div className='footer-content-itemThree-inner'><FontAwesomeIcon icon={faList} /><div className='footer-content-itemThree-title'>Listing</div></div></div>
                    <div className='footer-content-itemFour' onClick={() => navigate('/profile')}><div className='footer-content-itemFour-inner'><FontAwesomeIcon icon={faUser} /><div className='footer-content-itemFour-title'>Profile</div></div></div>
                </div>
            </div>
        </div>
    )
}

export default Footer;