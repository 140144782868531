import { useState } from "react";
// import emailjs from "emailjs-com";
import React from "react";
import axios from "axios";

export const Contact = (props) => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [message, setMessage] = useState('');
  const [showSuccessMessage, setShowSuccessMessage] = useState(false)

  const handleNameChange = (e) => {
    setName(e.target.value)
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value)
  };

  const handlePhoneChange = (e) => {
    setPhone(e.target.value)
  };

  const handleMessageChange = (e) => {
    setMessage(e.target.value)
  };

  const clearState = () => {
    setName('')
    setEmail('')
    setPhone('')
    setMessage('')
  };


  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(name, email, phone, message);
    axios({

      // Endpoint to send files
      url: "https://api.nearvisit.com/contact/create",
      method: "POST",
      headers: {

        // Add any auth token here
        authorization: localStorage.getItem('token'),
      },

      // Attaching the form data
      data: {
        name: name,
        email: email,
        phone: phone,
        message: message
      },
    })

      // Handle the response from backend here
      .then((res) => {
        console.log(res)
        clearState()
        setShowSuccessMessage(true)
        setTimeout(() => {
          setShowSuccessMessage(false)
        }, 4000)
      })

      // Catch errors if any
      .catch((err) => { });

    {/* replace below with your own Service ID, Template ID and Public Key from your EmailJS account */ }

    // emailjs
    //   .sendForm("YOUR_SERVICE_ID", "YOUR_TEMPLATE_ID", e.target, "YOUR_PUBLIC_KEY")
    //   .then(
    //     (result) => {
    //       console.log(result.text);
    //       clearState();
    //     },
    //     (error) => {
    //       console.log(error.text);
    //     }
    //   );
  };
  return (
    <div>
      <div id="contact">
        <div className="container">
          <div className="col-md-8">
            <div className="row">
              <div className="section-title">
                <h2>Get In Touch</h2>
                <p>
                  Please fill out the form below to send us an email and we will
                  get back to you as soon as possible.
                </p>
              </div>
              <form name="sentMessage" validate onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="text"
                        id="name"
                        name="name"
                        className="form-control"
                        placeholder="Name"
                        required
                        onChange={handleNameChange}
                        value={name}
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="email"
                        id="email"
                        name="email"
                        className="form-control"
                        placeholder="Email"
                        required
                        onChange={handleEmailChange}
                        value={email}
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="number"
                        id="phone"
                        name="phone"
                        className="form-control"
                        placeholder="Phone Number"
                        required
                        onChange={handlePhoneChange}
                        value={phone}
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <textarea
                    name="message"
                    id="message"
                    className="form-control"
                    rows="4"
                    placeholder="Message"
                    required
                    onChange={handleMessageChange}
                    value={message}
                  ></textarea>
                  <p className="help-block text-danger"></p>
                </div>
                {showSuccessMessage && (
                  <div id="success" style={{ backgroundColor: "green", color: 'white' }}>
                    Form submitted successfully
                  </div>
                )}
                <button type="submit" className="btn btn-custom btn-lg">
                  Send Message
                </button>
              </form>
            </div>
          </div>
          <div className="col-md-3 col-md-offset-1 contact-info">
            <div className="contact-item">
              <h3>Contact Info</h3>
              <p>
                <span>
                  <i className="fa fa-map-marker"></i> Address
                </span>
                {props.data ? props.data.address : "loading"}
              </p>
            </div>
            <div className="contact-item">
              <p>
                <span>
                  <i className="fa fa-phone"></i> Phone
                </span>{" "}
                {props.data ? props.data.phone : "loading"}
              </p>
            </div>
            <div className="contact-item">
              <p>
                <span>
                  <i className="fa fa-envelope-o"></i> Email
                </span>{" "}
                {props.data ? props.data.email : "loading"}
              </p>
            </div>
          </div>
          <div className="col-md-12">
            <div className="row">
              <div className="social">
                <ul>
                  <li>
                    <a href={props.data ? props.data.facebook : "/"}>
                      <i className="fa fa-facebook"></i>
                    </a>
                  </li>
                  <li>
                    <a href={props.data ? props.data.twitter : "/"}>
                      <i className="fa fa-twitter"></i>
                    </a>
                  </li>
                  <li>
                    <a href={props.data ? props.data.youtube : "/"}>
                      <i className="fa fa-youtube"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="footer">
        <div className="container text-center">
          <p>
            &copy; Near Visit a product of SAANCHITA Local search (opc) Pvt. Ltd
          </p>
        </div>
      </div>
    </div>
  );
};