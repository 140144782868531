
import React, { useEffect, useState } from "react";
import './header.scss';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

export const Header = (props) => {
  const [completePlacesData, setCompletePlacesData] = useState([])
  const [placeData, setPlaceData] = useState([])
  const [isPlaceDataLoaded, setIsPlaceDataLoaded] = useState(false)
  const navigate = useNavigate();

  useEffect(() => {
    axios({

      // Endpoint to send files
      url: "https://api.nearvisit.com/place/",
      method: "GET",
      headers: {

        // Add any auth token here
        authorization: "your token comes here",
      },

      // Attaching the form data
      // data: { name: name},
    })

      // Handle the response from backend here
      .then((res) => {
        console.log(res.data.place)
        setCompletePlacesData(res.data.place)
        let fetchedPlaces = []
        res.data.place.find((v, i) => {
          fetchedPlaces.push(v.name)
        })
        setPlaceData(fetchedPlaces)
        setIsPlaceDataLoaded(true)
      })

      // Catch errors if any
      .catch((err) => { });
  }, [])

  const handleSearchInput = (e) => {
    console.log(e.target.value, 'e.target.value')
  }

  return (
    <header id="header">
      <div className="intro">
        <div className="overlay">
          <div className="container">
            <div className="row">
              <div className="col-md-8 col-md-offset-2 intro-text">
                <h2>
                  {props.data ? props.data.title : "Loading"}
                  <span></span>
                </h2>
                <p>{props.data ? props.data.paragraph : "Loading"}</p>
                <div className="search-container">
                  <div className="search-content">
                    {
                      isPlaceDataLoaded && <Autocomplete
                        disablePortal
                        onChange={(event, newValue) => {
                          // setValue(newValue);
                          console.log(event, 'event')
                          console.log(newValue, 'newvalue')
                          let foundSelectedState = "";
                          completePlacesData.find((v,i) => {
                            if(v.name === newValue) {
                              foundSelectedState = v.state
                            }
                          })
                          navigate(`/businesses-in-${newValue}-${foundSelectedState.replace(/\s/g,'')}`)
                        }}
                        id="combo-box-demo"
                        options={placeData}
                        sx={{ width: 300 }}
                        renderInput={(params) => <TextField {...params} label="Enter your place" />}
                      />
                    }
                  </div>
                </div>
                <a
                  href="#features"
                  className="btn btn-custom btn-lg page-scroll"
                >
                  Search
                </a>{" "}
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};
