import React, { useEffect, useState } from "react";
import './categories.scss';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';

export const Categories = (props) => {
  const navigate = useNavigate();
  const [isSummaryDataLoaded, setIsSummaryDataLoaded] = useState(false)
  const [summaryData, setSummaryData] = useState([])
  const location = useLocation();

  useEffect(() => {
    axios({

      // Endpoint to send files
      url: "https://api.nearvisit.com/category/allcategorieswithsubcategories/all",
      method: "GET",
      headers: {

        // Add any auth token here
        authorization: localStorage.getItem('token'),
      },

      // Attaching the form data
      // data: {
      //     phone: phone,
      //     password: password
      // },
    })

      // Handle the response from backend here
      .then((res) => {
        console.log(res)
        setSummaryData(res.data.data)
        setIsSummaryDataLoaded(true)
      })

      // Catch errors if any
      .catch((err) => { });

  }, [])

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  return (
    <div id="features" className="text-center">
      <div className="container">
        <div className="col-md-10 col-md-offset-1 section-title">
          <h2>Categories</h2>
        </div>
        <div className="categories-main-container">
          {isSummaryDataLoaded && summaryData.map((v, i) => {
            return <div className="categories-title-container" key={i}>
              <div className="categories-title-info">
                {capitalizeFirstLetter(v.category.name)}
              </div>
              <div className="categories-title-subcategory">
                {v.subcategories.map((x, y) => {
                  return <button key={y} className="location-places-button" onClick={() => navigate(`/${v.category.name.replace(/\s/g, '')}-${x.name.replace(/\s/g, '')}-in-${props.place}-${props.state}`)}>
                    <p>{capitalizeFirstLetter(x.name)}</p>
                  </button>
                })}
              </div>
            </div>
          })}
        </div>
      </div>
    </div>
  );
};