import React, { useState } from 'react';
import './Signup.component.scss';
import { TextField } from '@mui/material';
import axios from "axios";
import { useNavigate } from 'react-router-dom';

const Signup = () => {
    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('')
    const navigate = useNavigate();
    const [error, setError] = useState({ message: ''});
    const [isSignupValid, setIsSignupValid] = useState(false)


    const handleForm = (e) => {
        console.log(e.target.name)
        console.log(e.target.value)
        if (e.target.name == "name") {
            setName(e.target.value)
            if(phone && password && email) {
                setIsSignupValid(true)
            } else {
                setIsSignupValid(false)
            }
        }
        if (e.target.name == "phone") {
            // setPhone(e.target.value)
            if(e.target.value.length > 10) {
                console.log('more than 10')
                // setError({ message: 'Phone number cannot be more than 10 numbers'})
            } else {
                setPhone(e.target.value)
            }
            if(name && password && email) {
                setIsSignupValid(true)
            } else {
                setIsSignupValid(false)
            }
        }
        if (e.target.name == "password") {
            setPassword(e.target.value)
            if(name && phone && email) {
                setIsSignupValid(true)
            } else {
                setIsSignupValid(false)
            }
        }
        if (e.target.name == "email") {
            setEmail(e.target.value)
            if(name && phone && password) {
                setIsSignupValid(true)
            } else {
                setIsSignupValid(false)
            }
        }
    }

    const handleSubmit = (e) => {
        if(name === "" ) {
            setError({ message: 'Name cannot be empty'})
            setIsSignupValid(false)
        } else if (email === ""){
            setError({ message: 'Email cannot be empty'})
            setIsSignupValid(false)
        } else if (phone === "") {
            setError({ message: 'Phone number cannot be empty'})
            setIsSignupValid(false)
        } else if (phone.length <10 ) {
            setError({ message: 'Phone number cannot be lesser than 10 numbers'})
            setIsSignupValid(false)
        } else if (phone.length >10 ) {
            setError({ message: 'Phone number cannot be greater than 10 numbers'})
            setIsSignupValid(false)
        } else if (password === ""){
            setError({ message: 'Password cannot be empty'})
            setIsSignupValid(false)
        } else {
            setError({ message: ''})
            setIsSignupValid(true)

            axios({

                // Endpoint to send files
                url: "https://api.nearvisit.com/user/signup",
                method: "POST",
                headers: {
        
                  // Add any auth token here
                  authorization: "your token comes here",
                },
        
                // Attaching the form data
                data: {
                    name: name,
                    email: email,
                    phone: phone,
                    password: password
                },
              })
        
                // Handle the response from backend here
                .then((res) => {
                  console.log(res)
                  navigate('/login')
                //   setRowData(res.data.category)
                })
        
                // Catch errors if any
                .catch((err) => { });
              // axios.get()
        }
    }

    const handleLogin = () => {
        if(name === "" ) {
            setIsSignupValid(false)
        } else if (password === ""){
            setIsSignupValid(false)
        } else if (phone === "") {
            setIsSignupValid(false)
        }
        navigate('/login')
    }

    const handleBlur = () => {
        if(name === "" ) {
            setError({ message: 'Name cannot be empty'})
            setIsSignupValid(false)
        } else if (email === ""){
            setError({ message: 'Email cannot be empty'})
            setIsSignupValid(false)
        } else if (phone === "") {
            setError({ message: 'Phone number cannot be empty'})
            setIsSignupValid(false)
        } else if (phone.length <10 ) {
            setError({ message: 'Phone number cannot be lesser than 10 numbers'})
            setIsSignupValid(false)
        } else if (phone.length >10 ) {
            setError({ message: 'Phone number cannot be greater than 10 numbers'})
            setIsSignupValid(false)
        } else if (password === ""){
            setError({ message: 'password cannot be empty'})
            setIsSignupValid(false)
        } else {
            setError({ message: ''})
            setIsSignupValid(true)
        }
    }

    return (
        <div className='signup-main-container'>
            <div className='signup-inner-container'>
                <div className='signup-content'>
                    <div className='signup-content-title'>
                        Signup
                    </div>
                    {
                        error && error ? (
                            <div className='signup-error-message'>{error.message}</div>
                        ) : ''
                    }
                    <TextField className='signup-form-field' onBlur={handleBlur} id="outlined-basic" type='text' label="Name" variant="outlined" name="name" onChange={handleForm} value={name} />
                    <TextField className='signup-form-field' onBlur={handleBlur} id="outlined-basic" type='text' label="Email" variant="outlined" name="email" onChange={handleForm} value={email} />
                    <TextField className='signup-form-field' onBlur={handleBlur} id="outlined-basic" type="number" label="Phone Number" variant="outlined" name="phone" onChange={handleForm} value={phone} />
                    <TextField className='signup-form-field' onBlur={handleBlur} id="outlined-basic" type="password" label="Password" variant="outlined" name="password" onChange={handleForm} value={password} />
                    <div className='signup-content-action'>
                        <button className='signup-content-submit' style={isSignupValid ? ({ backgroundColor: 'blue'}) : ({ backgroundColor: 'grey'})} disabled={!isSignupValid} onClick={handleSubmit}>Submit</button>
                        <button className='signup-content-cancel'>Cancel</button>
                    </div>
                    <div className='signup-content-signup'>
                        <div className='signup-content-signup-desc'>Already have an account? <span onClick={handleLogin} style={{ color: 'darkblue', cursor: 'pointer' }}>Login</span></div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Signup;