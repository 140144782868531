import React, { useEffect, useState } from "react";
import "./ShowAllBusinesses.component.scss";
import { Navigation } from "../../Components/ShowAllCategories/navigation.jsx";
import { Header } from "../../Components/ShowAllCategories/header.jsx";
import { Businesses } from "./businesses.jsx";
import { Contact } from "./contact.jsx";
import JsonData from "../../Components/ShowAllBusinesses/data/data.json";

export const ShowAllBusinesses = () => {
  const [landingPageData, setLandingPageData] = useState({});
  useEffect(() => {
    setLandingPageData(JsonData);
  }, []);

  return (
    <div>
      <Navigation />
      <Header data={landingPageData.Header} />
      <Businesses />
      <Contact data={landingPageData.Contact} />
    </div>
  );
};
