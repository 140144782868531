import React, { useEffect, useState } from "react";
import './locations.scss';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const andhrapradeshData = [
  { state: "andhrapradesh", place: "srikakulam" },
  { state: "andhrapradesh", place: "parvathipuram" },
  { state: "andhrapradesh", place: "vizianagaram" },
  { state: "andhrapradesh", place: "visakhapatnam" },
  { state: "andhrapradesh", place: "paderu" },
  { state: "andhrapradesh", place: "anakapalli" },
  { state: "andhrapradesh", place: "kakinada" },
  { state: "andhrapradesh", place: "rajahmundry" },
  { state: "andhrapradesh", place: "amalapuram" },
  { state: "andhrapradesh", place: "eluru" },
  { state: "andhrapradesh", place: "bhimavaram" },
  { state: "andhrapradesh", place: "vijayawada" },
  { state: "andhrapradesh", place: "machilipatnam" },
  { state: "andhrapradesh", place: "narasaraopeta" },
  { state: "andhrapradesh", place: "guntur" },
  { state: "andhrapradesh", place: "bapatla" },
  { state: "andhrapradesh", place: "ongole" },
  { state: "andhrapradesh", place: "nellore" },
  { state: "andhrapradesh", place: "kurnool" },
  { state: "andhrapradesh", place: "nandyal" },
  { state: "andhrapradesh", place: "anantapuram" },
  { state: "andhrapradesh", place: "puttaparthi" },
  { state: "andhrapradesh", place: "kadapa" },
  { state: "andhrapradesh", place: "rayachoti" },
  { state: "andhrapradesh", place: "tirupati" },
  { state: "andhrapradesh", place: "chittoor" }
]

const telanganaData = [
  { state: "telangana", place: "adilabad" },
  { state: "telangana", place: "asifabad" },
  { state: "telangana", place: "mancherial" },
  { state: "telangana", place: "nirmal" },
  { state: "telangana", place: "nizamabad" },
  { state: "telangana", place: "jagtial" },
  { state: "telangana", place: "peddapalli" },
  { state: "telangana", place: "kamareddy" },
  { state: "telangana", place: "sircilla" },
  { state: "telangana", place: "karimnagar" },
  { state: "telangana", place: "bhupalpally" },
  { state: "telangana", place: "sangareddy" },
  { state: "telangana", place: "medak" },
  { state: "telangana", place: "siddipet" },
  { state: "telangana", place: "jangaon" },
  { state: "telangana", place: "hanamkonda" },
  { state: "telangana", place: "warangal" },
  { state: "telangana", place: "mulugu" },
  { state: "telangana", place: "kothagudem" },
  { state: "telangana", place: "khammam" },
  { state: "telangana", place: "mahabubabad" },
  { state: "telangana", place: "suryapet" },
  { state: "telangana", place: "bhuvanagiri" },
  { state: "telangana", place: "shamirpet" },
  { state: "telangana", place: "shamshabad" },
  { state: "telangana", place: "vikarabad" },
  { state: "telangana", place: "narayanpet" },
  { state: "telangana", place: "mahabubnagar" },
  { state: "telangana", place: "nagarkurnool" },
  { state: "telangana", place: "wanaparthy" },
  { state: "telangana", place: "gadwal" }
]

export const Locations = (props) => {
  const navigate = useNavigate();
  const [andhrapradeshData, setAndhrapradeshData] = useState([])
  const [telanganaData, setTelanganaData] = useState([])
  const [isAndhrapradeshDataLoaded, setIsAndhrapradeshDataLoaded] = useState(false)
  const [isTelanganaDataLoaded, setIsTelanganaDataLoaded] = useState(false)

  useEffect(() => {
    axios({

      // Endpoint to send files
      url: "https://api.nearvisit.com/place/",
      method: "GET",
      headers: {

          // Add any auth token here
          authorization: "your token comes here",
      },

      // Attaching the form data
      // data: { name: name},
  })

      // Handle the response from backend here
      .then((res) => {
          console.log(res.data.place)
          // setPlaceData(res.data.place)
          let tempAndhrapradeshData = [];
          let tempTelanganaData = [];
          res.data.place.find((v,i) => {
            if(v.state === "andhra pradesh") {
              tempAndhrapradeshData.push(v)
              setIsAndhrapradeshDataLoaded(true)
            } else if (v.state === "telangana") {
              tempTelanganaData.push(v)
              setIsTelanganaDataLoaded(true)
            }
          })
          setAndhrapradeshData(tempAndhrapradeshData)
          setTelanganaData(tempTelanganaData)

      })

      // Catch errors if any
      .catch((err) => { });
  }, [])
  return (
    <div id="features" className="text-center">
      <div className="container">
        <div className="col-md-10 col-md-offset-1 section-title">
          <h2>Locations</h2>
        </div>
        <div className="locations-places-state-container">
          <div className="locations-places-state-info">
            Andhra Pradesh
          </div>
          <div className="locations-places-container">
            {isAndhrapradeshDataLoaded && andhrapradeshData.map((v, i) => {
              return <button className="location-places-button" onClick={() => navigate(`/businesses-in-${v.name}-${v.state.replace(/\s/g,'')}`)}>
                <p>{v.name}</p>
              </button>
            })}
          </div>
        </div>
        <div className="locations-places-state-container">
          <div className="locations-places-state-info">
            Telangana
          </div>
          <div className="locations-places-container">
            {isTelanganaDataLoaded && telanganaData.map((v, i) => {
              return <button className="location-places-button" onClick={() => navigate(`/businesses-in-${v.name}-${v.state.replace(/\s/g,'')}`)}>
                <p>{v.name}</p>
              </button>
            })}
          </div>
        </div>
      </div>
    </div>
  );
};