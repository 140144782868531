import React, { useState, useEffect } from "react";
import { Navigation } from "../../Components/home/navigation";
import { Header } from "../../Components/home/header";
import { Contact } from "../../Components/home/contact";
import JsonData from "../../Components/home/data/data.json";
import "./Home.component.css";
import { Locations } from "../../Components/home/locations";

const App = () => {
  const [landingPageData, setLandingPageData] = useState({});
  useEffect(() => {
    setLandingPageData(JsonData);
  }, []);

  return (
    <div>
      <Navigation />
      <Header data={landingPageData.Header} />
      <Locations />
      <Contact data={landingPageData.Contact} />
    </div>
  );
};

export default App;
