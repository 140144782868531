import React from 'react';
import './PaymentSuccessPage.component.scss';
import success from '../../assets/payment-page/checked.png';
import { useNavigate } from 'react-router-dom';

const PaymentSuccessPage = () => {
    const navigate = useNavigate();

    return (
        <div>
        <div className='payment-success-page-main'>
            <div className='payment-success-page-inner'>
                <div className='payment-success-message-container'>
                    <div className='payment-success-message-text'>
                        Thank you for making the payment. We will verify and make your business live
                    </div>
                    <img src={success} />
                    <div className='payment-success-message-redirect'>
                        <button style={{ color: 'white', backgroundColor: 'blue', cursor: 'pointer', borderRadius: 5}} onClick={() => navigate('/')}> Click to go home page</button>
                    </div>
                </div>
            </div>
        </div>
        </div>
    )
}

export default PaymentSuccessPage;