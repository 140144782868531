import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

export const Navigation = (props) => {
  const navigate = useNavigate();
  const [userName, setUserName] = useState('')

  useEffect(() => {
    axios({

      // Endpoint to send files
      url: "https://api.nearvisit.com/user/checkUser",
      method: "POST",
      headers: {

        // Add any auth token here
        authorization: "your token comes here",
      },

      // Attaching the form data
      data: { token: localStorage.getItem('token') },
    })

      // Handle the response from backend here
      .then((res) => {
        console.log(res)
        setUserName(res.data.name)
      })

      // Catch errors if any
      .catch((err) => { });
  }, [])

  const handleLogout = () => {
    localStorage.removeItem('token')
    navigate('/login')
  }

  const handleLogin = () => {
    navigate('/login')
  }

  return (
    <nav id="menu" className="navbar navbar-default navbar-fixed-top">
      <div className="container">
        <div className="navbar-header">
          <button
            type="button"
            className="navbar-toggle collapsed"
            data-toggle="collapse"
            data-target="#bs-example-navbar-collapse-1"
          >
            {" "}
            <span className="sr-only">Toggle navigation</span>{" "}
            <span className="icon-bar"></span>{" "}
            <span className="icon-bar"></span>{" "}
            <span className="icon-bar"></span>{" "}
          </button>
          <a className="navbar-brand page-scroll" style={{ cursor: 'pointer' }} onClick={() => navigate('/')}>
            Near Visit
          </a>{" "}
        </div>

        <div
          className="collapse navbar-collapse"
          id="bs-example-navbar-collapse-1"
        >
          <ul className="nav navbar-nav navbar-right">
            <li>
              <a>
                <span style={{ color: 'blue' }}>Hi, Welcome {userName ? userName : 'Guest'}</span>
              </a>
            </li>
            <li>
              <a href="" className="page-scroll" onClick={() => navigate('/business-listing-create')}>
                List Business
              </a>
            </li>
            <li>
              <a href="" className="page-scroll" onClick={() => navigate('/business-listing')}>
                My Businesses
              </a>
            </li>
            <li>
              {/* <a href="#about" className="page-scroll"> */}
              {localStorage.getItem('token') && userName ? (<a href="" onClick={handleLogout} style={{ textDecoration: 'none', color: 'crimson' }}>Logout</a>) : (<a href="" onClick={handleLogin} style={{ textDecoration: 'none' }}>Signup / Login</a>)}

              {/* </a> */}
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};