import React, { useEffect, useState } from "react";
import "./IndexBusinessListing.component.scss";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Navigation } from "../IndexBusinessListing/navigation.jsx";
import { Features } from "./features.tsx";
import { Contact } from "./contact.jsx";
import JsonData from "./data/data.json";

const IndexBusinessListing = () => {
  const navigate = useNavigate();
  const [landingPageData, setLandingPageData] = useState({});

  useEffect(() => {
    axios({
      // Endpoint to send files
      url: "https://api.nearvisit.com/user/checkUser",
      method: "POST",
      headers: {
        // Add any auth token here
        authorization: "your token comes here",
      },

      // Attaching the form data
      data: { token: localStorage.getItem("token") },
    })
      // Handle the response from backend here
      .then((res) => {
        console.log(res);
        if (res.data.name) {
          setLandingPageData(JsonData);
        }
      })

      // Catch errors if any
      .catch((err) => {
        navigate("/login");
      });
  }, []);

  return (
    <>
      <Navigation />
      <Features />
      <Contact data={landingPageData.Contact} />
    </>
  );
};

export default IndexBusinessListing;
