import React, { useEffect, useState } from "react";
import { Navigation } from "../../Components/ShowAllCategories/navigation.jsx";
import { Header } from "../../Components/ShowAllCategories/header.jsx";
import { Categories } from "./categories.jsx";
import { Contact } from "./contact.jsx";
import JsonData from "../../Components/ShowAllCategories/data/data.json";
import { useNavigate, useLocation } from 'react-router-dom';

const ShowAllCategories = () => {
  const [landingPageData, setLandingPageData] = useState({});
  const location = useLocation();
  useEffect(() => {
    console.log(location,'in showAllCategories')
    setLandingPageData(JsonData);
  }, []);

  return (
    <div>
      <Navigation />
      <Header data={landingPageData.Header} />
      <Categories state={location.state.state} place={location.state.place} />
      <Contact data={landingPageData.Contact} />
    </div>
  );
};

export default ShowAllCategories;
