import React, { useEffect, useState } from 'react';
import './features.scss';
import axios from 'axios';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableHead from '@mui/material/TableHead';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import Navbar from '../../Navbar/Navbar.component.tsx';

export const Features = (props) => {
  const navigate = useNavigate();
  // const { businessId } = useParams();
  // console.log(businessId, 'businessId')
  const [businessData, setBusinessData] = useState({} as businessData)
  const { state } = useLocation();


  useEffect(() => {
    axios({

      // Endpoint to send files
      url: `https://api.nearvisit.com/business/${state.businessId}`,
      method: "GET",
      headers: {

        // Add any auth token here
        authorization: localStorage.getItem('token'),
      },

      // Attaching the form data
      // data: { name: name},
    })

      // Handle the response from backend here
      .then((res) => {
        console.log(res)
        setBusinessData(res.data.business)
      })

      // Catch errors if any
      .catch((err) => { });
  }, [])

  // const handleEditBusiness = () => {
  //     navigate(`/business-listing/${businessId}/edit`)
  // }

  const handleBusinessStatus = (e) => {
    console.log(e.target.checked)
    axios({

      // Endpoint to send files
      url: `https://api.nearvisit.com/business/${state.businessId}/edit`,
      method: "PUT",
      headers: {

        // Add any auth token here
        authorization: localStorage.getItem('token'),
      },

      // Attaching the form data
      data: { status: e.target.checked ? 'active' : 'inactive' },
    })

      // Handle the response from backend here
      .then((res) => {
        console.log(res)
        // setBusinessData(res.data.business)
      })

      // Catch errors if any
      .catch((err) => { });
    axios({

      // Endpoint to send files
      url: `https://api.nearvisit.com/business/${state.businessId}`,
      method: "GET",
      headers: {

        // Add any auth token here
        authorization: localStorage.getItem('token'),
      },

      // Attaching the form data
      // data: { name: name},
    })

      // Handle the response from backend here
      .then((res) => {
        console.log(res)
        setBusinessData(res.data.business)
      })

      // Catch errors if any
      .catch((err) => { });
  }

  // window.addEventListener('popstate', function (event) {
  //   window.location.reload()
  //   navigate('/')
  // }, false);


  return (
    <div id="features" className="text-center">
      <div className="container">
        <div className='row'>
          <h2>m</h2>
        </div>
        <div className="col-md-10 col-md-offset-1 section-title">
          <h2>Business</h2>
        </div>
        <div className="row">
          <div className='business-listing-detail-info-content'>
            <div className='business-listing-detail-info-content-field'>
              <div className='business-listing-detail-info-content-field-key'>Business Name<span color='red'>*</span>:</div>
              <div className='business-listing-detail-info-content-field-value'>{businessData.businessName}</div>
            </div>
            <div className='business-listing-detail-info-content-field'>
              <div className='business-listing-detail-info-content-field-key'>Business Door Number<span color='red'>*</span>:</div>
              <div className='business-listing-detail-info-content-field-value'>{businessData.doorNumber}</div>
            </div>
            <div className='business-listing-detail-info-content-field'>
              <div className='business-listing-detail-info-content-field-key'>Business Locality<span color='red'>*</span>:</div>
              <div className='business-listing-detail-info-content-field-value'>{businessData.locality}</div>
            </div>
            <div className='business-listing-detail-info-content-field'>
              <div className='business-listing-detail-info-content-field-key'>Business Pincode<span color='red'>*</span>:</div>
              <div className='business-listing-detail-info-content-field-value'>{businessData.pincode}</div>
            </div>
            <div className='business-listing-detail-info-content-field'>
              <div className='business-listing-detail-info-content-field-key'>Business Phone Number<span color='red'>*</span>:</div>
              <div className='business-listing-detail-info-content-field-value'>{businessData.phoneNumber}</div>
            </div>
            <div className='business-listing-detail-info-content-field'>
              <div className='business-listing-detail-info-content-field-key'>Business Email<span color='red'>*</span>:</div>
              <div className='business-listing-detail-info-content-field-value'>{businessData.email}</div>
            </div>
            <div className='business-listing-detail-info-content-field'>
              <div className='business-listing-detail-info-content-field-key'>Business State<span color='red'>*</span>:</div>
              <div className='business-listing-detail-info-content-field-value'>{businessData.state}</div>
            </div>
            <div className='business-listing-detail-info-content-field'>
              <div className='business-listing-detail-info-content-field-key'>Business Place<span color='red'>*</span>:</div>
              <div className='business-listing-detail-info-content-field-value'>{businessData.place}</div>
            </div>
            <div className='business-listing-detail-info-content-field'>
              <div className='business-listing-detail-info-content-field-key'>Business Category<span color='red'>*</span>:</div>
              <div className='business-listing-detail-info-content-field-value'>{businessData.category}</div>
            </div>
            <div className='business-listing-detail-info-content-field'>
              <div className='business-listing-detail-info-content-field-key'>Business Sub Category<span color='red'>*</span>:</div>
              <div className='business-listing-detail-info-content-field-value'>{businessData.subCategory}</div>
            </div>
            <div className='business-listing-detail-info-content-field'>
              <div className='business-listing-detail-info-content-field-key'>Business Referrer:</div>
              <div className='business-listing-detail-info-content-field-value'>{businessData.referrer}</div>
            </div>
            <div className='business-listing-detail-info-content-field'>
              <div className='business-listing-detail-info-content-field-key'>Business Status:</div>
              <div className='business-listing-detail-info-content-field-value'>{businessData.status}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};