import React, { useEffect, useState } from 'react';
import './ViewBusiness.component.scss';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import axios from 'axios';

const ViewBusiness = () => {
    const navigate = useNavigate();
    const { state } = useLocation();
    const { businessId } = useParams();
    const [businessData, setBusinessData] = useState({})
    const [status, setStatus] = useState({ message: '' })

    useEffect(() => {
        console.log(state, 'state')
        axios({

            // Endpoint to send files
            url: `https://api.nearvisit.com/business/${businessId}`,
            method: "GET",
            headers: {

                // Add any auth token here
                authorization: "your token comes here",
            },

            // Attaching the form data
            // data: { name: name},
        })

            // Handle the response from backend here
            .then((res) => {
                setBusinessData(res.data.business)
            })

            // Catch errors if any
            .catch((err) => { });
    }, [])

    return (
        <>
            <div className="view-business-main-container">
                <div className='view-business-inner-container'>
                    <div className='view-business-content'>


                        <div className='view-business-title-container'>
                            <div className='view-business-title-value'>
                                {businessData.businessName}
                            </div>
                        </div>
                        <div className='view-business-status-message'>
                            {
                                status.message && status.message ? (
                                    <div className='view-business-status-value'>{status.message}</div>
                                ) : ''
                            }
                        </div>
                        <div className='view-business-back-container'>
                            <div className='view-business-back-button' onClick={() => navigate('/')}>
                                {/* <img src={`https://nearvisit-website.s3.ap-south-1.amazonaws.com/common/back-arrow.png`} /> */}
                                <p>back</p>
                            </div>
                        </div>
                        <div className='view-business-detail-info-content'>
                            <div className='view-business-detail-info-content-field'>
                                <div className='view-business-detail-info-content-field-key'>Business Rating:</div>
                                <div className='view-business-detail-info-content-field-value'>({businessData.ratingValue != "NaN" && businessData.ratingValue ? businessData.ratingValue : '0'})/5 ({businessData.ratingTotal} reviews)</div>
                            </div>
                            <div className='view-business-detail-info-content-field'>
                                <div className='view-business-detail-info-content-field-key'>Business Door Number:</div>
                                <div className='view-business-detail-info-content-field-value'>{businessData.doorNumber}</div>
                            </div>
                            <div className='view-business-detail-info-content-field'>
                                <div className='view-business-detail-info-content-field-key'>Business Locality:</div>
                                <div className='view-business-detail-info-content-field-value'>{businessData.locality}</div>
                            </div>
                            <div className='view-business-detail-info-content-field'>
                                <div className='view-business-detail-info-content-field-key'>Business Pincode:</div>
                                <div className='view-business-detail-info-content-field-value'>{businessData.pincode}</div>
                            </div>
                            <div className='view-business-detail-info-content-field'>
                                <div className='view-business-detail-info-content-field-key'>Business Phone Number:</div>
                                <div className='view-business-detail-info-content-field-value'>{businessData.phoneNumber}</div>
                            </div>
                            <div className='view-business-detail-info-content-field'>
                                <div className='view-business-detail-info-content-field-key'>Business Email:</div>
                                <div className='view-business-detail-info-content-field-value'>{businessData.email}</div>
                            </div>
                            <div className='view-business-detail-info-content-field'>
                                <div className='view-business-detail-info-content-field-key'>Business State:</div>
                                <div className='view-business-detail-info-content-field-value'>{businessData.state}</div>
                            </div>
                            <div className='view-business-detail-info-content-field'>
                                <div className='view-business-detail-info-content-field-key'>Business Place:</div>
                                <div className='view-business-detail-info-content-field-value'>{businessData.place}</div>
                            </div>
                            <div className='view-business-detail-info-content-field'>
                                <div className='view-business-detail-info-content-field-key'>Business Category:</div>
                                <div className='view-business-detail-info-content-field-value'>{businessData.category}</div>
                            </div>
                            <div className='view-business-detail-info-content-field'>
                                <div className='view-business-detail-info-content-field-key'>Business Sub Category:</div>
                                <div className='view-business-detail-info-content-field-value'>{businessData.subCategory}</div>
                            </div>
                            <div className='view-business-detail-info-content-field'>
                                <div className='view-business-detail-info-content-field-key'>Business Referrer:</div>
                                <div className='view-business-detail-info-content-field-value'>{businessData.referrer}</div>
                            </div>
                            <div className='view-business-detail-info-content-field'>
                                <div className='view-business-detail-info-content-field-key'>Business Status:</div>
                                <div className='view-business-detail-info-content-field-value'>{businessData.status}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ViewBusiness;