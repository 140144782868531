
import React from "react";
import './header.scss';

export const Header = (props) => {

  return (
    <header id="showallcategories-header">
      <div className="intro">
        <div className="overlay">
          <div className="container">
            <div className="row">
              <div className="showallcategories-header-content">
                {/* <h2>
                  {props.data ? props.data.title : "Loading"}
                  <span></span>
                </h2> */}
                <p>{props.data ? props.data.paragraph : "Loading"}</p>
                <div className="search-container">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};
