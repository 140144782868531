import React, { useEffect, useState } from "react";
import "./businesses.scss";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";

export const Businesses = (props) => {
  const navigate = useNavigate();
  const [isSummaryDataLoaded, setIsSummaryDataLoaded] = useState(false);
  const [summaryData, setSummaryData] = useState([]);
  const location = useLocation();

  useEffect(() => {
    if (location.pathname.split("-")[0].split("/")[1] === "businesses") {
      navigate("/categories", {
        state: {
          state: location.pathname.split("-")[3],
          place: location.pathname.split("-")[2],
        },
      });
    } else {
      axios({
        // Endpoint to send files
        url: "https://api.nearvisit.com/business/search",
        method: "POST",
        headers: {
          // Add any auth token here
          authorization: localStorage.getItem("token"),
        },

        // Attaching the form data
        data: {
          state: location.pathname.split("-")[4],
          place: location.pathname.split("-")[3],
          category: location.pathname.split("-")[0].split("/")[1],
          subCategory: location.pathname.split("-")[1],
        },
      })
        // Handle the response from backend here
        .then((res) => {
          console.log(res.data.results, "res");
          setSummaryData(res.data.results);
          setIsSummaryDataLoaded(true);
        })
        .catch((err) => {});
    }
  }, []);

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  return (
    <div id="features" className="text-center">
      <div className="container">
        <div className="col-md-10 col-md-offset-1 section-title">
          <h2>Businesses</h2>
        </div>
        <div className="categories-main-container">
          {
            summaryData.length == 0 && (
              <div className="no-result-found-container">
              <h3>No Results Found</h3>
              </div>
            )
          }
          {isSummaryDataLoaded &&
            summaryData.map((v, i) => {
              return (
                <div className="categories-title-container" key={i}>
                  <div className="categories-title-info">
                    {capitalizeFirstLetter(v.businessName)}
                  </div>
                  <div className="categories-title-content">
                    <div className="categories-title-content-businessName">
                      <span style={{ fontWeight: 600 }}>Business Name : </span>
                      {v.businessName}
                    </div>
                    <div className="categories-title-content-businessCategory">
                      <span style={{ fontWeight: 600 }}>
                        Business Category :{" "}
                      </span>
                      {v.category}
                    </div>
                    <div className="categories-title-content-businessSubcategory">
                      <span style={{ fontWeight: 600 }}>
                        Business Sub Category :{" "}
                      </span>
                      {v.subCategory}
                    </div>
                    <div className="categories-title-content-businessState">
                      <span style={{ fontWeight: 600 }}>Business State : </span>
                      {v.state}
                    </div>
                    <div className="categories-title-content-businessPlace">
                      <span style={{ fontWeight: 600 }}>Business Place : </span>
                      {v.place}
                    </div>
                    <div className="categories-title-content-businessName">
                      <span style={{ fontWeight: 600 }}>
                        Business Door Number :{" "}
                      </span>
                      {v.doorNumber}
                    </div>
                    <div className="categories-title-content-businessCategory">
                      <span style={{ fontWeight: 600 }}>
                        Business locality :{" "}
                      </span>
                      {v.locality}
                    </div>
                    <div className="categories-title-content-businessSubcategory">
                      <span style={{ fontWeight: 600 }}>
                        Business pin code :{" "}
                      </span>
                      {v.pincode}
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
};
