
import React, { useEffect, useState } from "react";
import './header.scss';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import axios from 'axios';
import { useNavigate, useLocation } from 'react-router-dom';

export const Header = (props) => {
  const [completeSubcategoriesData, setCompleteSubcategoriesData] = useState([])
  const [subcategoriesData, setSubcategoriesData] = useState([])
  const [isSubcategoriesDataLoaded, setIsCategoriesDataLoaded] = useState(false)
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    axios({

      // Endpoint to send files
      url: "https://api.nearvisit.com/subcategory/",
      method: "GET",
      headers: {

        // Add any auth token here
        authorization: "your token comes here",
      },

      // Attaching the form data
      // data: { name: name},
    })

      // Handle the response from backend here
      .then((res) => {
        console.log(res.data.subcategory)
        setCompleteSubcategoriesData(res.data.subcategory)
        let fetchedSubcategories = []
        res.data.subcategory.find((v, i) => {
          fetchedSubcategories.push(v.name)
        })
        setSubcategoriesData(fetchedSubcategories)
        setIsCategoriesDataLoaded(true)
      })

      // Catch errors if any
      .catch((err) => { });
  }, [])

  const handleSearchInput = (e) => {
    console.log(e.target.value, 'e.target.value')
  }

  return (
    <header id="showallcategories-header">
      <div className="intro">
        <div className="overlay">
          <div className="container">
            <div className="row">
              <div className="showallcategories-header-content">
                {/* <h2>
                  {props.data ? props.data.title : "Loading"}
                  <span></span>
                </h2> */}
                <p>{props.data ? props.data.paragraph : "Loading"}</p>
                <div className="search-container">
                  {/* <div className="search-content">
                    {
                      isSubcategoriesDataLoaded && <Autocomplete
                        disablePortal
                        onChange={(event, newValue) => {
                          // setValue(newValue);
                          console.log(event, 'event')
                          console.log(newValue, 'newvalue')
                          // let foundSelectedSubcategory = "";
                          // completeSubcategoriesData.find((v,i) => {
                          //   if(v.name === newValue) {
                          //     foundSelectedSubcategory = v.state
                          //   }
                          // })
                          console.log(location.pathname.split('-'), 'location')
                          navigate(`/businesses/${newValue}-in-${location.pathname.split('-')[2]}-${location.pathname.split('-')[3].replace(/\s/g,'')}`)
                        }}
                        id="combo-box-demo"
                        options={subcategoriesData}
                        sx={{ width: 300 }}
                        renderInput={(params) => <TextField {...params} label="search business category" />}
                      />
                    }
                  </div> */}
                </div>
                {/* <a
                  href="#features"
                  className="btn btn-custom btn-lg page-scroll"
                >
                  Search
                </a>{" "} */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};
