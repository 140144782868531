import React, { useEffect, useState } from "react";
import './CreateBusinessForm.scss';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import { TextField } from '@mui/material';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { Helmet } from "react-helmet";

export const CreateBusinessForm = (props) => {
  const navigate = useNavigate();

  const [businessName, setBusinessName] = useState('');
  const [doorNumber, setDoorNumber] = useState('');
  const [locality, setLocality] = useState('');
  const [pincode, setPincode] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('')
  const [email, setEmail] = useState('')
  const [stateData, setStateData] = useState([]);
  const [selectedState, setSelectedState] = useState('')
  const [place, setPlace] = useState('');
  const [placeData, setPlaceData] = useState([])
  const [category, setCategory] = useState('');
  const [categoryData, setCategoryData] = useState([])
  const [subCategory, setSubCategory] = useState('');
  const [subCategoryData, setSubCategoryData] = useState([])
  const [referrer, setReferrer] = useState('')
  const [isFormValid, setIsFormValid] = useState(false)
  const [error, setError] = useState({ message: '', type: '' })
  const [selectedPackage, setSelectedPackage] = useState('normal')
  const [positions, setPositions] = useState([])
  const [position, setPosition] = useState('')
  const [showPositions, setShowPositions] = useState(false)

  const packages = [
    { id: 1, name: "normal" },
    { id: 2, name: "premium" }
  ]

  useEffect(() => {
    axios({

      // Endpoint to send files
      url: "https://api.nearvisit.com/state/",
      method: "GET",
      headers: {

        // Add any auth token here
        authorization: "your token comes here",
      },

      // Attaching the form data
      // data: { name: name},
    })

      // Handle the response from backend here
      .then((res) => {
        console.log(res)
        setStateData(res.data.state)
      })

      // Catch errors if any
      .catch((err) => { });

    axios({

      // Endpoint to send files
      url: "https://api.nearvisit.com/place/",
      method: "GET",
      headers: {

        // Add any auth token here
        authorization: "your token comes here",
      },

      // Attaching the form data
      // data: { name: name},
    })

      // Handle the response from backend here
      .then((res) => {
        console.log(res)
        setPlaceData(res.data.place)
      })

      // Catch errors if any
      .catch((err) => { });

    axios({

      // Endpoint to send files
      url: "https://api.nearvisit.com/category/",
      method: "GET",
      headers: {

        // Add any auth token here
        authorization: localStorage.getItem('token'),
      },

      // Attaching the form data
      // data: { name: name},
    })

      // Handle the response from backend here
      .then((res) => {
        console.log(res)
        setCategoryData(res.data.category)
      })

      // Catch errors if any
      .catch((err) => { });

    axios({

      // Endpoint to send files
      url: "https://api.nearvisit.com/subcategory/",
      method: "GET",
      headers: {

        // Add any auth token here
        authorization: "your token comes here",
      },

      // Attaching the form data
      // data: { name: name},
    })

      // Handle the response from backend here
      .then((res) => {
        console.log(res)
        setSubCategoryData(res.data.subcategory)
      })

      // Catch errors if any
      .catch((err) => { });

  }, [])


  const handlePlace = (e: SelectChangeEvent) => {
    console.log(e.target.value)
    setPlace(e.target.value)
  }

  const handleState = (e: SelectChangeEvent) => {
    console.log(e.target.value)
    setSelectedState(e.target.value)
    RefreshPlaceData(e.target.value)
  }

  const handleCategory = (e: SelectChangeEvent) => {
    console.log(e.target.value)
    setCategory(e.target.value)
    RefreshSubCategoryData(e.target.value)
  }

  const handleSubCategory = (e: SelectChangeEvent) => {
    console.log(e.target.value)
    setSubCategory(e.target.value)
  }

  const RefreshSubCategoryData = (categoryInput) => {
    axios({

      // Endpoint to send files
      url: "https://api.nearvisit.com/subcategory/",
      method: "GET",
      headers: {

        // Add any auth token here
        authorization: "your token comes here",
      },

      // Attaching the form data
      // data: { name: name},
    })

      // Handle the response from backend here
      .then((res) => {
        console.log(res)
        // setSubCategoryData(res.data.subcategory)
        let foundSubCategories = [];
        res.data.subcategory.find((v, i) => {
          if (categoryInput == v.category) {
            console.log(v, 'v')
            foundSubCategories.push(v)
          }
        })
        if (foundSubCategories.length > 0) {
          setSubCategoryData(foundSubCategories)
          setSubCategory(foundSubCategories[0].name)
        } else {
          setSubCategoryData([{ id: "Not Available", category: "Not Available", name: "Not Available" }])
          setSubCategory("Not Available")
          console.log(foundSubCategories.length, 'foundSubCategories.length')
        }
      })

      // Catch errors if any
      .catch((err) => { });
  }

  const RefreshPlaceData = (stateInput) => {
    axios({

      // Endpoint to send files
      url: "https://api.nearvisit.com/place/",
      method: "GET",
      headers: {

        // Add any auth token here
        authorization: "your token comes here",
      },

      // Attaching the form data
      // data: { name: name},
    })

      // Handle the response from backend here
      .then((res) => {
        console.log(res)
        // setPlaceData(res.data.place)
        let foundPlaces = [];
        res.data.place.find((v, i) => {
          if (stateInput == v.state) {
            console.log(v, 'v')
            foundPlaces.push(v)
          }
        })
        if (foundPlaces.length > 0) {
          setPlaceData(foundPlaces)
          setPlace(foundPlaces[0].name)
        } else {
          setPlaceData([{ id: "Not Available", state: "Not Available", name: "Not Available" }])
          setPlace("Not Available")
          console.log(foundPlaces.length, 'foundPlaces.length')
        }
      })

      // Catch errors if any
      .catch((err) => { });
  }

  const handleFormCancel = () => {
    navigate('/business-listing')
  }

  const handleForm = (e) => {
    // validateForm()
    if (e.target.name == "businessName") {
      setBusinessName(e.target.value)
    }

    if (e.target.name == "doorNumber") {
      setDoorNumber(e.target.value)
    }

    if (e.target.name == "locality") {
      setLocality(e.target.value)
    }

    if (e.target.name == "pincode") {
      setPincode(e.target.value)
    }

    if (e.target.name == "phoneNumber") {
      if (e.target.value.length > 10) {
        console.log('more than 10')
        // setError({ message: 'Phone number cannot be more than 10 numbers'})
      } else {
        setPhoneNumber(e.target.value)
      }
    }

    if (e.target.name == "email") {
      setEmail(e.target.value)
    }
  }

  const validateForm = () => {
    if (businessName && doorNumber && locality && pincode && phoneNumber && email && selectedState && place && category && subCategory) {
      setIsFormValid(true)
      return true
    } else {
      setIsFormValid(false)
      setError({ message: 'Please fill the required fields', type: 'error' })
      return false
    }
  }

  const handlePayment = () => {
    let RefactoredState = ""
    let RefactoredPlace = ""
    let RefactoredCategory = ""
    let RefactoredSubcategory = ""
    let combinedAllData = stateData.concat(placeData, categoryData, subCategoryData)
    console.log(combinedAllData, 'combinedAllData')
    console.log(selectedState.replace(/\s/g, ''), 'selectedState')
    console.log(place, 'place')
    console.log(category, 'category')
    console.log(subCategory, 'subCategory')
    combinedAllData.find((v, i) => {
      v.name.replace(/\s/g, '')
      if (v.name == selectedState) {
        RefactoredState = v.name
      } else if (v.name === place) {
        RefactoredPlace = v.name
      } else if (v.name == category) {
        RefactoredCategory = v.name
      } else if (v.name === subCategory) {
        RefactoredSubcategory = v.name
      } else {
        console.log('not condition met')
      }
    })
    console.log(RefactoredState, 'RefactoredState')
    console.log(RefactoredPlace, 'RefactoredPlace')
    console.log(RefactoredCategory, 'RefactoredCategory')
    console.log(RefactoredSubcategory, 'RefactoredSubcategory')

    // return false
    let validateFormResponse = validateForm();
    if (validateFormResponse) {
      let createBusinessForm = {
        businessName: businessName,
        doorNumber: doorNumber,
        locality: locality,
        pincode: pincode,
        phoneNumber: phoneNumber,
        email: email,
        state: RefactoredState,
        place: RefactoredPlace,
        category: RefactoredCategory,
        subCategory: RefactoredSubcategory,
        referrer: referrer,
        selectedPackage: selectedPackage,
        position: position
      }

      console.log(createBusinessForm, 'createBusinessForm')
      axios({

        // Endpoint to send files
        url: "https://api.nearvisit.com/business/create",
        method: "POST",
        headers: {

          // Add any auth token here
          authorization: localStorage.getItem('token'),
        },

        // Attaching the form data
        data: createBusinessForm,
      })

        // Handle the response from backend here
        .then((res) => {
          console.log(res)
          navigate('/business-listing')
          // setSubCategoryData(res.data.subcategory)
        })

        // Catch errors if any
        .catch((err) => { });

        window.open('https://rzp.io/l/GpBxUGeQD', '_blank');

      // if (position == '1') {
      //   window.open('https://rzp.io/l/0TpmRwl', '_blank');
      // } else if (position == '2') {
      //   window.open('https://rzp.io/l/PfvxMSKQi', '_blank');
      // } else if (position == '3') {
      //   window.open('https://rzp.io/l/8S3dDFJOh', '_blank');
      // } else if (position == '4') {
      //   window.open('https://rzp.io/l/TFKsxeWUD8', '_blank');
      // } else if (position == '5') {
      //   window.open('https://rzp.io/l/JKBnKIaTQ', '_blank');
      // } else if (position == '6') {
      //   window.open('https://rzp.io/l/4xaq5gaa', '_blank');
      // } else if (position == '7') {
      //   window.open('https://rzp.io/l/i6wsns3S', '_blank');
      // } else if (position == '8') {
      //   window.open('https://rzp.io/l/oZQB0WllP', '_blank');
      // } else if (position == '9') {
      //   window.open('https://rzp.io/l/fqiGnM1ugQ', '_blank');
      // } else if (position == '10') {
      //   window.open('https://rzp.io/l/jW9KSW3', '_blank');
      // } else if (selectedPackage == "normal") {
      //   window.open('https://rzp.io/l/4xaq5gaa', '_blank');
      // }
    } else {

    }

  }

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  const handleSelectPackage = (e: SelectChangeEvent) => {

    console.log(e.target.value, 'e.target.value')
    setSelectedPackage(e.target.value)
    if (e.target.value === "premium") {
      setShowPositions(false)
      console.log('in premium')
      axios({

        // Endpoint to send files
        url: "https://api.nearvisit.com/position/search",
        method: "Post",
        headers: {

          // Add any auth token here
          authorization: "your token comes here",
        },

        // Attaching the form data
        data: { state: selectedState, place: place, category: category, subcategory: subCategory },
      })

        // Handle the response from backend here
        .then((res) => {
          console.log(res)
          setShowPositions(true)
          setPositions(res.data.positions)
        })

        // Catch errors if any
        .catch((err) => { });
    } else {
      setPositions([])
      setShowPositions(false)
    }
  }

  const handleSelectPosition = (e: SelectChangeEvent) => {
    console.log(e.target.value, 'e.target.value')
    setPosition(e.target.value)
  }

  return (
    <div id="features" className="text-center">
      <div className="container">
        <div className="col-md-10 col-md-offset-1 section-title">
          <h2>Create Business</h2>
        </div>
        <div className="create-business-main-container">
          <div className="create-business-inner-container">
            <div className='create-business-description-container'>
              <div className='create-business-description-title'>
                STEP 1:
                Good businesses are not OK, help us to find the Great businesses. Simply fill out and submit this secure form. It takes a lot of guts to call your business "The Best" and we respect that!
                <br /> <br /> <br />
                STEP 2:
                Manual review takes 7 business days. Business will be accepted, based solely on the business's reputation, history, complaints, reviews, location, ratings, satisfaction, trust, cost and general excellence. We only accept businesses with best business practices.
                <br /> <br /> <br />
                STEP 3:
                If accepted, the business will be listed on nearvisit.com
              </div>
            </div>
            <div className='create-business-form'>
              <TextField className='create-business-form-field' id="outlined-basic" label="Name of the Business" name="businessName" value={businessName} onChange={handleForm} variant="outlined" />
              <TextField className='create-business-form-field' id="outlined-basic" label="Door Number" name="doorNumber" value={doorNumber} onChange={handleForm} variant="outlined" />
              <TextField className='create-business-form-field' id="outlined-basic" label="Locality" name="locality" value={locality} onChange={handleForm} variant="outlined" />
              <TextField className='create-business-form-field' id="outlined-basic" label="Pin code" name="pincode" value={pincode} onChange={handleForm} variant="outlined" type="number" />
              <TextField className='create-business-form-field' id="outlined-basic" label="Phone number" name="phoneNumber" value={phoneNumber} onChange={handleForm} variant="outlined" type='number' />
              <TextField className='create-business-form-field' id="outlined-basic" label="Email Address" name="email" value={email} onChange={handleForm} variant="outlined" />
              <FormControl className='create-business-form-field' fullWidth>
                <InputLabel id="demo-simple-select-label">State</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={selectedState}
                  label="State"
                  onChange={handleState}
                >
                  {
                    stateData.map((v, i) => {
                      return <MenuItem key={i} value={v.name}>{capitalizeFirstLetter(v.name)}</MenuItem>
                    })
                  }
                </Select>
              </FormControl>
              <FormControl className='create-business-form-field' fullWidth>
                <InputLabel id="demo-simple-select-label">Place</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={place}
                  label="Place"
                  onChange={handlePlace}
                >
                  {
                    placeData.map((v, i) => {
                      return <MenuItem key={i} value={v.name}>{capitalizeFirstLetter(v.name)}</MenuItem>
                    })
                  }
                </Select>
              </FormControl>
              <FormControl className='create-business-form-field' fullWidth>
                <InputLabel id="demo-simple-select-label">Category</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={category}
                  label="Category"
                  onChange={handleCategory}
                >
                  {
                    categoryData.map((v, i) => {
                      return <MenuItem key={i} value={v.name}>{capitalizeFirstLetter(v.name)}</MenuItem>
                    })
                  }
                </Select>
              </FormControl>
              <FormControl className='create-business-form-field' fullWidth>
                <InputLabel id="demo-simple-select-label">Sub Category</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={subCategory}
                  label="Sub Category"
                  onChange={handleSubCategory}
                >
                  {
                    subCategoryData.map((v, i) => {
                      return <MenuItem key={i} value={v.name}>{capitalizeFirstLetter(v.name)}</MenuItem>
                    })
                  }
                </Select>
              </FormControl>
              {/* <FormControl className='create-business-form-field' fullWidth>
                <InputLabel id="demo-simple-select-label">Select Package</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={selectedPackage}
                  label="Select Package"
                  onChange={handleSelectPackage}
                >
                  {
                    packages.map((v, i) => {
                      return <MenuItem key={i} value={v.name}>{capitalizeFirstLetter(v.name)}</MenuItem>
                    })
                  }
                </Select>
              </FormControl>
              {console.log(showPositions, 'showPositions')}
              {showPositions && positions && (
                <FormControl className='create-business-form-field' fullWidth>
                  <InputLabel id="demo-simple-select-label">Select Position</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={position}
                    label="Select Position"
                    onChange={handleSelectPosition}
                  >
                    {
                      positions.map((v, i) => {
                        return <MenuItem key={i} value={v.positionNumber}>{`Position: ${capitalizeFirstLetter(v.positionNumber)}, Price: ${v.positionAmount}`}</MenuItem>
                      })
                    }
                  </Select>
                </FormControl>
              )} */}
              <TextField className='create-business-form-field' id="outlined-basic" label="Referrer" name="referrer" value={referrer} onChange={handleForm} variant="outlined" />
            </div>
            {
              error.message && (
                <div style={{ color: 'red' }}>{error.message}</div>
              )
            }
            { selectedPackage == "normal" ? (
                <div>Normal Package: Rs.2599/-</div>
            ) : ""}
            <div className='create-business-action-button-container'>
              <div className='create-business-action-button'>
                {/* <button style={isFormValid ? { backgroundColor: 'darkblue'} : { backgroundColor: 'grey'}} disabled={isFormValid ? false : true} className='create-business-action-payment' onClick={handlePayment}>Pay Now</button> */}
                <button className='create-business-action-payment' onClick={handlePayment}>Pay Now</button>
                <button className='create-business-action-cancel' onClick={handleFormCancel}>Cancel</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};